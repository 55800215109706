import React from 'react';
import { useAppWrapperLogic } from './AppWrapper.logic';
import { RtlProvider } from 'root/components/Menus/shared/RtlProvider/RtlProvider';

export const AppWrapper = ({ children }: { children: React.ReactNode }) => {
  const { presetName } = useAppWrapperLogic();

  return (
    <RtlProvider>
      <div className={`preset ${presetName}`}>{children}</div>
    </RtlProvider>
  );
};
