import React from 'react';
import type { FC } from 'react';
import type { WidgetProps } from '@wix/yoshi-flow-editor';
import { Menu } from 'root/components/Menus/Menu/Menu';
import type { PopulatedMenu } from 'root/apiTypes';
import { dataHooks } from 'root/dataHooks';
import type { NavigateToFn } from 'root/clientTypes';
import { useMenusLogic } from './Widget.logic';
import { AppWrapper } from 'root/components/Menus/AppWrapper/AppWrapper';
import { classes } from './Widget.st.css';
import { Navigation } from 'root/components/Menus/Navigation/Navigation';

export type ControllerProps = {
  menus: PopulatedMenu[];
  navigateTo: NavigateToFn;
};

export const Widget: FC<WidgetProps<ControllerProps>> = ({ menus, navigateTo }) => {
  const { activeTab, displayableMenus, shouldShowTabs, setActiveTab } = useMenusLogic({
    menus,
  });

  return (
    <AppWrapper>
      <div className={classes.root} data-hook={dataHooks.menus.container}>
        {shouldShowTabs && (
          <Navigation
            activeItem={activeTab}
            menus={displayableMenus}
            navigateTo={navigateTo}
            setActiveItem={setActiveTab}
          />
        )}
        {displayableMenus?.length > 0 ? <Menu menu={displayableMenus[activeTab]} /> : <div>Empty state</div>}
      </div>
    </AppWrapper>
  );
};
