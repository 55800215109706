import { useStyles } from '@wix/tpa-settings/react';
import type { CSSProperties } from 'react';
import { Preset } from 'root/utils/presets';
import { stylesParams } from 'root/components/Menus/stylesParams';
import { getAlignmentText, useGetAlignment } from 'root/hooks/useGetAlignment';
import { Alignment } from 'root/consts';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export const useLabelsLogic = () => {
  const styles = useStyles();
  const { isRTL } = useEnvironment();

  const preset = styles.get(stylesParams.preset) as Preset;
  const gridItemAlignment = useGetAlignment('gridItemAlignment');

  const alignmentMap: Record<Preset, string> = {
    [Preset.Grid]: gridItemAlignment,
    [Preset.Columns]: getAlignmentText(isRTL ? Alignment.Right : Alignment.Left),
    [Preset.Center]: getAlignmentText(Alignment.Center),
  };

  const justifySelfCss = { justifySelf: alignmentMap[preset] as CSSProperties['justifySelf'] };

  return { justifySelfCss };
};
