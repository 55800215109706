import { StyleParamType, wixColorParam, createStylesParams, wixFontParam } from '@wix/tpa-settings';
import type { StylesParamsType } from 'root/components/Menus/stylesParams';
import { NavigationDropdownStyle } from 'root/consts';

type IMenusTabsNavigationStyleParams = {
  navigationTabsTextColor: StyleParamType.Color;
  navigationTabsTextFont: StyleParamType.Font;
  navigationTabsSelectedIndicatorColor: StyleParamType.Color;
};

export const navigationTabsStyleParams = createStylesParams<IMenusTabsNavigationStyleParams>({
  navigationTabsTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  navigationTabsTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  navigationTabsSelectedIndicatorColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
});

type IMenusNavigationDropdownStyleParams = {
  navigationDropdownStyle: StyleParamType.Number;
  navigationDropdownTextColor: StyleParamType.Color;
  navigationDropdownTextFont: StyleParamType.Font;
  navigationDropdownBackgroundColor: StyleParamType.Color;
  navigationDropdownBorderColor: StyleParamType.Color;
  navigationDropdownBorderWidth: StyleParamType.Number;
  navigationDropdownBorderRadius: StyleParamType.Number;
};

export const navigationDropdownStyleParams = createStylesParams<IMenusNavigationDropdownStyleParams>({
  navigationDropdownStyle: {
    type: StyleParamType.Number,
    getDefaultValue: () => NavigationDropdownStyle.Box,
  },
  navigationDropdownTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  navigationDropdownTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  navigationDropdownBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  navigationDropdownBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  navigationDropdownBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  navigationDropdownBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
});

type IMenusNavigationStylesParams = IMenusTabsNavigationStyleParams & IMenusNavigationDropdownStyleParams;

export const menusNavigationStylesParams = {
  ...navigationTabsStyleParams,
  ...navigationDropdownStyleParams,
};

type IMenusStylesParams = {
  menuBackgroundColor: StyleParamType.Color;
  // menuBackgroundShadow: StyleParamType.Color;
  menuBorderColorLeft: StyleParamType.Color;
  menuBorderColorTop: StyleParamType.Color;
  menuBorderColorRight: StyleParamType.Color;
  menuBorderColorBottom: StyleParamType.Color;
  menuBorderWidthLeft: StyleParamType.Number;
  menuBorderWidthTop: StyleParamType.Number;
  menuBorderWidthRight: StyleParamType.Number;
  menuBorderWidthBottom: StyleParamType.Number;
  menuBorderRadiusStartEnd: StyleParamType.Number;
  menuBorderRadiusStartStart: StyleParamType.Number;
  menuBorderRadiusEndEnd: StyleParamType.Number;
  menuBorderRadiusEndStart: StyleParamType.Number;
  menuTitleTheme: StyleParamType.Font;
  menuTitleColor: StyleParamType.Color;
  menuDescriptionTheme: StyleParamType.Font;
  menuDescriptionColor: StyleParamType.Color;
};

export const menusStylesParams = createStylesParams<IMenusStylesParams>({
  menuBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  // menuBackgroundShadow: {
  //   type: StyleParamType.Color,
  //   getDefaultValue: wixColorParam('color-1'),
  // },
  menuBorderColorLeft: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  menuBorderColorTop: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  menuBorderColorRight: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  menuBorderColorBottom: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  menuBorderWidthLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuBorderWidthTop: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuBorderWidthRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuBorderWidthBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuBorderRadiusStartEnd: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuBorderRadiusStartStart: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuBorderRadiusEndEnd: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuBorderRadiusEndStart: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuTitleTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 32,
    }),
  },
  menuTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  menuDescriptionTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  menuDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
});

type ISectionsStylesParams = {
  sectionBackgroundColor: StyleParamType.Color;
  // sectionBackgroundShadow: StyleParamType.Color;
  sectionBorderColorLeft: StyleParamType.Color;
  sectionBorderColorRight: StyleParamType.Color;
  sectionBorderColorTop: StyleParamType.Color;
  sectionBorderColorBottom: StyleParamType.Color;
  sectionBorderWidthLeft: StyleParamType.Number;
  sectionBorderWidthRight: StyleParamType.Number;
  sectionBorderWidthTop: StyleParamType.Number;
  sectionBorderWidthBottom: StyleParamType.Number;
  sectionBorderRadiusStartEnd: StyleParamType.Number;
  sectionBorderRadiusStartStart: StyleParamType.Number;
  sectionBorderRadiusEndEnd: StyleParamType.Number;
  sectionBorderRadiusEndStart: StyleParamType.Number;
  sectionTitleTheme: StyleParamType.Font;
  sectionTitleColor: StyleParamType.Color;
  sectionDescriptionTheme: StyleParamType.Font;
  sectionDescriptionColor: StyleParamType.Color;
  sectionTopDividerWidth: StyleParamType.Number;
  sectionTopDividerColor: StyleParamType.Color;
  sectionBottomDividerWidth: StyleParamType.Number;
  sectionBottomDividerColor: StyleParamType.Color;
};

export const sectionsStylesParams = createStylesParams<ISectionsStylesParams>({
  sectionBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  // sectionBackgroundShadow: {
  //   type: StyleParamType.Color,
  //   getDefaultValue: wixColorParam('color-1'),
  // },
  sectionBorderColorLeft: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionBorderColorRight: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionBorderColorTop: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionBorderColorBottom: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionBorderWidthLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionBorderWidthRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionBorderWidthTop: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionBorderWidthBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionBorderRadiusStartEnd: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionBorderRadiusStartStart: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionBorderRadiusEndEnd: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionBorderRadiusEndStart: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionTitleTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 24,
    }),
  },
  sectionTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionDescriptionTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  sectionDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionTopDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionTopDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  sectionBottomDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionBottomDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
});

type IItemsStylesParams = {
  itemBackgroundColor: StyleParamType.Color;
  // itemBackgroundShadow: StyleParamType.Color;
  itemBorderColorLeft: StyleParamType.Color;
  itemBorderColorRight: StyleParamType.Color;
  itemBorderColorTop: StyleParamType.Color;
  itemBorderColorBottom: StyleParamType.Color;
  itemBorderWidthLeft: StyleParamType.Number;
  itemBorderWidthRight: StyleParamType.Number;
  itemBorderWidthTop: StyleParamType.Number;
  itemBorderWidthBottom: StyleParamType.Number;
  itemBorderRadiusStartEnd: StyleParamType.Number;
  itemBorderRadiusStartStart: StyleParamType.Number;
  itemBorderRadiusEndEnd: StyleParamType.Number;
  itemBorderRadiusEndStart: StyleParamType.Number;
  itemTitleTheme: StyleParamType.Font;
  itemTitleColor: StyleParamType.Color;
  itemDescriptionTheme: StyleParamType.Font;
  itemDescriptionColor: StyleParamType.Color;
  itemPriceTheme: StyleParamType.Font;
  itemPriceColor: StyleParamType.Color;
  itemImageRadiusStartEnd: StyleParamType.Number;
  itemImageRadiusStartStart: StyleParamType.Number;
  itemImageRadiusEndEnd: StyleParamType.Number;
  itemImageRadiusEndStart: StyleParamType.Number;
  itemDividerColor: StyleParamType.Color;
  itemDividerWidth: StyleParamType.Number;
};

export const itemsStylesParams = createStylesParams<IItemsStylesParams>({
  itemBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  // itemBackgroundShadow: {
  //   type: StyleParamType.Color,
  //   getDefaultValue: wixColorParam('color-1'),
  // },
  itemBorderColorLeft: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemBorderColorRight: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemBorderColorTop: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemBorderColorBottom: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemBorderWidthLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemBorderWidthRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemBorderWidthTop: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemBorderWidthBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemBorderRadiusStartEnd: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemBorderRadiusStartStart: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemBorderRadiusEndEnd: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemBorderRadiusEndStart: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemTitleTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 16,
    }),
  },
  itemTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemDescriptionTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  itemDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemPriceTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  itemPriceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemImageRadiusStartEnd: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemImageRadiusStartStart: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemImageRadiusEndEnd: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemImageRadiusEndStart: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  itemDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
});

type IVariantsStylesParams = {
  variantTitleTheme: StyleParamType.Font;
  variantTitleColor: StyleParamType.Color;
  variantPriceTheme: StyleParamType.Font;
  variantPriceColor: StyleParamType.Color;
  variantDividerColor: StyleParamType.Color;
};

export const variantsStylesParams = createStylesParams<IVariantsStylesParams>({
  variantTitleTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  variantTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  variantPriceTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  variantPriceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  variantDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
});

type ILabelsStylesParams = {
  labelBackgroundColor: StyleParamType.Color;
  labelBorderColor: StyleParamType.Color;
  labelBorderWidth: StyleParamType.Number;
  labelBorderRadius: StyleParamType.Number;
  labelTitleTheme: StyleParamType.Font;
  labelTitleColor: StyleParamType.Color;
  labelIconColor: StyleParamType.Color;
  labelIconSize: StyleParamType.Number;
  labelSpaceBetween: StyleParamType.Number;
  labelVerticalPadding: StyleParamType.Number;
  labelHorizontalPadding: StyleParamType.Number;
};

export const labelsStylesParams = createStylesParams<ILabelsStylesParams>({
  labelBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  labelBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.1),
  },
  labelBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  labelBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 12,
  },
  labelTitleTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  labelTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  labelIconColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  labelIconSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  labelSpaceBetween: {
    type: StyleParamType.Number,
    getDefaultValue: () => 8,
  },
  labelVerticalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  labelHorizontalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 12,
  },
});

export type IDesignStylesParams = IMenusNavigationStylesParams &
  IMenusStylesParams &
  ISectionsStylesParams &
  IItemsStylesParams &
  IVariantsStylesParams &
  ILabelsStylesParams;

export const DesignStylesParams: StylesParamsType<IDesignStylesParams> = {
  ...menusNavigationStylesParams,
  ...menusStylesParams,
  ...sectionsStylesParams,
  ...itemsStylesParams,
  ...variantsStylesParams,
  ...labelsStylesParams,
};
