import type { CSSProperties } from 'react';
import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';
import type { MenuCheckbox } from 'root/clientTypes';
import { isSectionChecked, isPriceShowed } from 'root/utils/display';
import { useGetDisplaySettingsParams } from 'root/hooks/useGetDisplaySettingsParams';
import { useItemsContext } from 'root/contexts/ItemsContext';
import { Preset } from 'root/utils/presets';
import { useStyles } from '@wix/tpa-settings/react';
import { stylesParams } from 'root/components/Menus/stylesParams';
import { getAlignmentText, useGetAlignment } from 'root/hooks/useGetAlignment';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Alignment } from 'root/consts';
import type { ItemVariant } from 'root/apiTypes';

export const useVariantLogic = ({ variant }: { variant: ItemVariant }) => {
  const { sectionId } = useItemsContext();
  const { isRTL } = useEnvironment();

  const styles = useStyles();
  const preset = styles.get(stylesParams.preset) as Preset;
  const gridItemAlignment = useGetAlignment('gridItemAlignment');

  const {
    showItemVariantsName,
    showItemVariantsPrice,
    showItemVariantsCurrency,
    showItemZeroPrice,
    showZeroPricingForAllMenus,
  } = useGetDisplayStylesParams();

  const { checkPricingOptionsMenus } = useGetDisplaySettingsParams();

  const formattedPrice = showItemVariantsCurrency
    ? variant.priceInfo.formattedPrice
    : variant.priceInfo.formattedPriceNoCurrency ?? '';

  const priceOptionSectionChecked =
    showZeroPricingForAllMenus || isSectionChecked(checkPricingOptionsMenus as MenuCheckbox[], sectionId ?? '');

  const shouldShowVariantsPrice = isPriceShowed({
    price: variant.priceInfo.price ?? '',
    showPrice: showItemVariantsPrice,
    showZeroPrice: showItemZeroPrice,
    sectionChecked: priceOptionSectionChecked,
  });

  const alignmentMap: Record<Preset, string> = {
    [Preset.Grid]: gridItemAlignment,
    [Preset.Columns]: getAlignmentText(isRTL ? Alignment.Right : Alignment.Left),
    [Preset.Center]: getAlignmentText(Alignment.Center),
  };

  const justifySelfCss = { justifySelf: alignmentMap[preset] as CSSProperties['justifySelf'] };

  return { formattedPrice, showItemVariantsName, shouldShowVariantsPrice, justifySelfCss };
};
